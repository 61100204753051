section.header .intro {
  height: 330px;
  background-image: url(/img/intro.jpg);
  background-position: 50% 50%;
  background-size: cover; }
  section.header .intro h1 {
    font-size: 120px;
    color: #fff;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.95);
    font-family: Rajdhani;
    background-color: rgba(0, 0, 0, 0.6);
    border: 5px solid #fff;
    padding: 0 10px; }

section.header .nav {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  position: relative;
  margin-bottom: 40px; }
  section.header .nav .me {
    z-index: 100;
    position: absolute;
    transform: translateY(-50%);
    top: 0;
    left: 0;
    border-radius: 100%;
    width: 140px;
    border: 5px solid #fff; }
  section.header .nav .menu {
    padding: 0;
    margin: 0 0 0 140px;
    list-style: none; }
    section.header .nav .menu li {
      display: inline-block; }
      section.header .nav .menu li a {
        display: block;
        padding: 10px 20px;
        text-transform: uppercase;
        text-decoration: none;
        font-family: Rajdhani;
        color: #333;
        cursor: pointer; }
      section.header .nav .menu li:hover a,
      section.header .nav .menu li.active a {
        background: white;
        background: linear-gradient(0deg, #f1f1f1 0%, white);
        color: #000; }

.btn.btn-social.btn-youtube {
  background-color: #fd434f; }

.btn.btn-social {
  color: #fff;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transition-duration: .2s,.2s;
  transition-duration: .2s,.2s; }

.btn.btn-social svg {
  fill: #fff;
  width: 23px; }
