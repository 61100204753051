.box .box-header {
  border-bottom: 1px solid #009cf1; }
  .box .box-header h1 {
    font-size: 16px;
    text-transform: uppercase; }

.box .box-content {
  padding: 10px 0 0 0; }
  .box .box-content ul.specs {
    font-size: 14px;
    padding: 0;
    margin: 0;
    list-style: none; }
    .box .box-content ul.specs li {
      padding: 5px 0;
      border-bottom: 1px solid #efefef; }
